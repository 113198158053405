<template>
  <div class="home__section">
    <div class="section__header">
      <div class="section__header-title">
        <h2>تقييمات العمــــلاء</h2>
        <span>
          <img
            src="~/assets/images/previous-arrow.svg"
            alt="bug"
            class="ml-1"
            @click="goToPreviousSlide"
          />
          <img
            src="~/assets/images/next-arrow.svg"
            alt="bug"
            @click="goToNextSlide"
          />
        </span>
      </div>
    </div>
    <v-row v-if="customerRatings && customerRatings.length > 0">
      <div class="rates-container">
        <v-carousel
          v-model="currentSlideIndex"
          :cycle="false"
          class="tool-carousel"
          ref="carousel"
          :show-arrows="false"
          :items-per-page="customerRatings / 4"
        >
          <div
            v-for="index in Math.ceil(customerRatings.length / itemsPerPage)"
            :key="index"
          >
            <v-carousel-item>
              <!-- <v-row class="d-flex justify-center align-center">
              <v-col cols="3"
               v-for="(customerRating, courseIndex) in customerRatings"
            :key="courseIndex"
              > -->
              <v-row>
                <v-col
                  v-for="(customerRating, courseIndex) in customerRatings.slice(
                    (index - 1) * itemsPerPage,
                    index * itemsPerPage,
                  )"
                  :key="courseIndex"
                >
                  <customer-ratings-card :rate="customerRating" />
                </v-col>
              </v-row>
              <!-- </v-col>
            </v-row> -->
            </v-carousel-item>
          </div>
        </v-carousel>
      </div>
    </v-row>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";

import CustomerRatingsCard from "~/components/cards/CustomerRatingsCard.vue";

import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();

const slideNumber = ref(0);
const currentSlideIndex = ref(0);
const itemsPerPage = ref(4);

const customerRatings = computed(() =>
  homeStore.customerRatings ? homeStore.customerRatings : {},
);

const updateItemsPerPage = () => {
  if (window.innerWidth >= 1200) {
    itemsPerPage.value = 4;
  } else {
    itemsPerPage.value = 2;
  }
};

const goToPreviousSlide = () => {
  if (currentSlideIndex.value > 0) {
    currentSlideIndex.value--;
  }
};

const goToNextSlide = () => {
  if (
    currentSlideIndex.value <
    Math.ceil(customerRatings.value.length / itemsPerPage.value) - 1
  ) {
    currentSlideIndex.value++;
  }
};

const displayedRatings = computed(() => {
  const start = currentSlideIndex.value * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return customerRatings.value.slice(start, end);
});

onMounted(() => {
  updateItemsPerPage();
  window.addEventListener("resize", updateItemsPerPage);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateItemsPerPage);
});
</script>

<style lang="scss" scoped>
.rates-container {
  position: relative !important;
  width: 100%;
  height: 400px;
  margin: 19px 0 0;
  padding: 40px 24px 16px 28px;
  border-radius: 30px;
  background-color: #f7f7f7;
}
.section__header {
  margin: 15px 0;
  .section__header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 2px;
  }
  h2 {
    font-size: 38px;
  }
}
@media (max-width: 768px) {
  .section__header {
    h2 {
      font-size: 28px;
    }
  }
}
</style>
