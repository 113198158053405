<template>
  <div class="section__header">
    <div class="section__header-title">
      <h2>{{ title }}</h2>
      <span>
        <nuxt-link
          v-if="title ==='المواد المخفضة' "
          :to="{path:`${link}`,
                query:{ all_reduced_courses: true }}"
        >
          <v-btn class="show-more">
            عرض الكل
            <img src="~/assets/images/arrow.svg" alt="bug">
          </v-btn>
        </nuxt-link>
        <nuxt-link v-else :to="link">
          <v-btn class="show-more">
            عرض الكل
            <img src="~/assets/images/arrow.svg" alt="bug">
          </v-btn>
        </nuxt-link>
      </span>
    </div>
    <span v-if="subTitle" class="sub-title">{{ subTitle }}</span>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    title: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    link: { type: String, default: "" }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.section__header {
  margin: 15px 0;
  .section__header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 2px;
  }
  h2 {
    font-size: 38px;
  }
  .sub-title {
    font-size: 22px;
    font-weight: normal;
    line-height: 1.86;
  }
  .show-more {
    border-radius: 18px;
    background-color: #f4f4f4;
    font-size: 18px;
    font-weight: bold;
    img {
      margin-right: 20px;
    }
  }
}
@media (max-width: 768px) {
  .section__header {
    h2 {
      font-size: 28px;
    }
    .sub-title {
      font-size: 14px;
    }
    .show-more {
      padding: 6px;
      font-size: 16px;
      border-radius: 10px;
      img {
        margin-right: 10px;
      }
    }
  }
}
</style>
