<template>
  <div class="home__section">
    <section-header
      title="المواد المخفضة"
      link="/materials"
      sub-title="في هذا القسم ، يمكنك الحصول على هذه المواد مجاناً عند وجود طلب واحد أو أكثر في حسابك ."
    />
    <v-row v-if="!isClosed && home.all_reduced_courses.length > 0">
      <v-col v-for="(course, i) in courses" :key="i" cols="6" lg="3">
        <course-card
          :course="course"
          :reduced-courses-section="true"
          @onFavorite="fetch"
        />
      </v-col>
    </v-row>
    <closed-section v-else />
  </div>
</template>

<script setup>
import { computed, watch, ref, onMounted } from "vue";

import CourseCard from "~/components/cards/CourseCard.vue";
import SectionHeader from "~/components/shared/SectionHeader.vue";
import ClosedSection from "~/components/shared/ClosedSection.vue";
import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();

const isClosed = ref(false);

const home = computed(() => homeStore.home);
const freeCourses = computed(() =>
  homeStore.home.all_reduced_courses ? homeStore.home.all_reduced_courses : [],
);
const courses = computed(() =>
  home.value ? home.value.all_reduced_courses : [],
);

watch(courses, (val) => {
  isClosed.value = val.length === 0;
});

const fetch = async () => {
  if (!homeStore.home) {
    await homeStore.fetchHome();
  }
};
</script>

<style lang="scss" scoped></style>
