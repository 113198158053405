<template>
  <div class="home__section">
    <section-header
      title="أبرز المقالات"
      sub-title="تابعوا كتابات د. سمية"
      link="/articles"
    />
    <v-row>
      <v-col v-for="(article, i) in articles" :key="i" cols="12" md="6">
        <Article-card :article="article" @onFavorite="fetch" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";

import SectionHeader from "~/components/shared/SectionHeader";
import ArticleCard from "~/components/cards/ArticleCard.vue";
import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();

const home = computed(() => homeStore.home);

const articles = computed(() => {
  return home.value ? home.value.articles : [];
});

const fetch = async () => {
  if (!homeStore.home) {
    await homeStore.fetchHome();
  }
};

</script>

<style lang="scss" scoped></style>
