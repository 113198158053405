<template>
  <div class="home__section">
    <section-header title="الإصدارات" link="/publications" />
    <v-row>
      <v-col
        v-for="(publication, i) in publications"
        :key="i"
        cols="12"
        sm="6"
        lg="4"
      >
        <publication-card :publication="publication" @onFavorite="fetch" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";

import SectionHeader from "~/components/shared/SectionHeader.vue";
import PublicationCard from "~/components/cards/PublicationCard.vue";

import { useHomeStore } from "~/stores/HomeStore";

const homeStore = useHomeStore();

const home = computed(() => homeStore.home);
const publications = computed(() =>
  home.value ? home.value.publications : []
);

const fetch = async () => {
  if (!homeStore.home) {
    await homeStore.fetchHome();
  }
};

</script>

<style lang="scss" scoped></style>
