<template>
  <div>
    <section
      class="closed-section d-flex justify-center flex-column align-center text-center"
    >
      <img src="~/assets/images/shared/lock.svg" alt="lock icon">
      <p class="closed-text">
        العملاء الأعزاء ، الاشتراك مغلق في {{ modelType }} مؤقتـاً . فضلاً
        المحاولة لاحقاً بعد الساعة 5 مساءً بتوقيت السعودية
      </p>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    modelType: {
      type: String,
      default: "المواد"
    }
  }
};
</script>

<style lang="scss" scoped>
.closed-section {
  img{
    width: 130px;
    @media (max-width: 700px) {
      width: 100px;
      margin-top: 10px;
    }

  }
  .closed-text {
    max-width: 500px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;

    @media (max-width: 700px) {
      max-width: auto;
      padding: 0 40px;
      font-size: 16px;
      margin-top: 15px;
    }
  }
}
</style>
