<template>
  <Alert
    :alert-visible="showAlert"
    :alert-data="alertData"
    @close="showAlert = false"
  />
  <div v-if="!isCommingSoon" class="home-container">
    <courses id="courses" />
    <bundles />
    <free-courses v-if="home && home.all_reduced_courses" id="freeCourses" />
    <customers-ratings />
    <Articles id="articles" />
    <publications id="publications" />
    <ebooks v-if="isThereEbooks" />
  </div>
  <div v-else>
    <CommingSoon />
  </div>
  <InformMeDialog
    :openInformMeDialog="showInformMeDialog"
    :isCustomerLogIn="isLogin"
    :productId="productId"
    :productType="productType"
    @on-success-message="showSuccessMessage"
    @on-close-informMe-dialog="(val) => (showInformMeDialog = val)"
  />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

import { useRoute, useRouter } from "vue-router";

import BackgroundImage from "~/components/shared/BackgroundImage.vue";
import Courses from "~/components/home/Courses";
import bundles from "~/components/home/Bundles";
import FreeCourses from "~/components/home/FreeCourses";
import CustomersRatings from "~/components/home/CustomersRatings";
import Articles from "~/components/home/Articles";
import Alert from "~/components/shared/Alert.vue";
import publications from "~/components/home/Publications.vue";
import Ebooks from "~/components/home/Ebooks.vue";
import CommingSoon from "~/components/CommingSoon.vue";
import InformMeDialog from "~/components/course/InformMeDialog";
import gtmEvents from "~/mixins/gtm-events";
import { useHomeStore } from "~/stores/HomeStore";
import { useAuthStore } from "~/stores/AuthStore";

const authStore = useAuthStore();
const homeStore = useHomeStore();

const route = useRoute();
const router = useRouter();

// initialize cookies
const productIdCookie = useCookie("productId", {
  expires: new Date(Date.now() + 60 * 60 * 1000), // 1 hour from now
});
const productTypeCookie = useCookie("productType", {
  expires: new Date(Date.now() + 60 * 60 * 1000), // 1 hour from now
});

const showAlert = ref(false);
const alertData = ref({});

const isLogin = computed(() => authStore.auth.isLoggedIn);
const user = computed(() => authStore.auth.user);
const home = computed(() => homeStore.home);
const isThereEbooks = computed(
  () =>
    home.value && home.value.online_books && home.value.online_books.length > 0,
);
const isFirstLoad = computed(() => homeStore.is_first_load);
const seo = computed(() => (home.value ? home.value.seo : {}));
const isCommingSoon = computed(() => {
  const soonValue =
    process.env.IS_COMMING_SOON ||
    process.env.isCommingSoonEnv ||
    homeStore.isCommingSoonConfig;
  return soonValue === "true";
});

const showInformMeDialog = ref(false);
const productId = computed(() => {
  return isLogin.value ? productIdCookie.value : "";
});

const productType = computed(() => {
  return isLogin.value ? productTypeCookie.value : "";
});

watch(
  () => user.value,
  (newValue) => {
    if (newValue && !home.value) {
      fetchHomeData();
    }
  },
  { deep: true },
);
watch(
  () => authStore.informMeWhenMaterialBeAvailable,
  (newValue) => {
    if (newValue == true && isLogin.value) {
      setTimeout(() => {
        showInformMeDialog.value = true;
      }, 2000);
    }
  },
  { deep: true },
);

const fetchHomeData = async () => {
  if (route.query) {
    homeStore.query = route.query;
  }
};

useHead({
  title: seo.value ? seo.value.title : "Sumaya369",
  meta: [
    {
      name: "description",
      content: seo.value ? seo.value.description : "Sumaya369",
    },
    { name: "keywords", content: seo.value ? seo.value.keywords : "Sumaya369" },
    { name: "author", content: seo.value ? seo.value.author : "Sumaya369" },
  ],
  script:
    process.client && user.value && user.value.email
      ? [
          {
            innerHTML: `
        window.criteo_q = window.criteo_q || [];
        window.criteo_q.push(
          { event: "setAccount", account: 106556 },
          { event: "setEmail", email: "${user.value.email}", hash_method: "none" },
          { event: "setSiteType", type: ${/iPad/.test(navigator.userAgent) ? '"t"' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? '"m"' : '"d"'} },
          { event: "setZipcode", zipcode: "##Zip Code##" },
          { event: "viewHome" }
        );
      `,
            type: "text/javascript",
          },
        ]
      : [],
});

const showSuccessMessage = (data) => {
  setAlertData({
    message: data.message,
    success: data.success,
  });
};
const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};
onMounted(async () => {
  if (route.query) {
    homeStore.query = route.query;
  }

  const ref = useCookie("ref");
  const promo = useCookie("promo");
  if (
    (homeStore.query.ref && !ref.value) ||
    (homeStore.query.ref && homeStore.query.ref !== ref.value)
  ) {
    const ref = useCookie("ref", {
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
    });

    ref.value = homeStore.query.ref || "";

    // store.$cookies.set("ref", homeStore.query.ref, "7d");
  }
  if (
    (homeStore.query.promo && !promo.value) ||
    (homeStore.query.promo && homeStore.query.promo !== promo.value)
  ) {
    const promo = useCookie("promo", {
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 7 days from now
    });

    promo.value = homeStore.query.promo || "";
    // store.$cookies.set("promo", homeStore.query.promo, "7d");
  }

  if (isFirstLoad.value && authStore.auth.isLoggedIn) {
    // gtmEvents.methods.trackingUserDetails(user.value);
    homeStore.is_first_load = false;
  }
  setTimeout(async () => {
    if (!homeStore.home) {
      await homeStore.fetchHome();
    }
    homeStore.fetchCountries({ list: true, select_country: true });
  }, 1000);

  // setTimeout(() => {
  //   fetchHomeData();
  // }, 500);
});

// if (!home.value) {
//   fetchHomeData();
// }

// setTimeout(async () => {
if (!homeStore.home) {
  homeStore.fetchHome();
}
if (!homeStore.customerRatings) {
  homeStore.fetchCustomerRatings({
    per_page: 4,
    page: 1,
  });
}

if (!homeStore.homeBundles) {
  await homeStore.fetchBundles({
    page: 1,
  });
  homeStore.loading = false;
}
// }, 700);
</script>

<style lang="scss" scoped>
.bundles-section {
  background-image: url("~/assets/images/shapes/bundles-small.svg"),
    url("~/assets/images/shapes/bundles-large.svg");
  background-size: 12%, 26%;
  background-repeat: no-repeat, no-repeat;
  background-position:
    8% 5%,
    104% 6%;
}

@media (min-width: 1920px) {
  .home-container {
    max-width: 1900px;
    margin: auto;
  }
}
</style>
