<template>
  <div>
    <v-card
      :style="smAndDown && customCard ? 'width: 70%' : ''"
      class="px-4 py-7 card"
    >
      <v-row>
        <v-col cols="3" sm="3" class="avatar-container">
          <v-avatar class="avatar">
            <img :src="rate.avatar" />
          </v-avatar>
        </v-col>
        <v-col cols="9" sm="6">
          <p class="mb-0 text-dark font-weight-bold text-md">{{ rate.name }}</p>
          <p class="mb-0 text-gray text-12">{{ rate.created_at }}</p>
        </v-col>
        <v-col cols="3" sm="3" class="pt-0 pb-5">
          <div class="star-container d-flex align-center">
            <img
              src="~/assets/images/shared/star-icon.svg"
              height="22"
              width="22"
            />
            <p class="mb-0 font-weight-bold text-dark">{{ rate.rate }}</p>
          </div>
        </v-col>
      </v-row>
      <v-chip class="mb-2 subject">
        <span class="font-weight-bold text-sm text-white">{{
          rate.subject
        }}</span>
      </v-chip>
      <div class="comment text-md">
        <p>{{ rate.comment }}</p>
      </div>
    </v-card>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";

const props = defineProps({
  rate: {
    type: Object,
    default: () => ({}),
  },
  customCard: {
    type: Boolean,
    default: false,
  },
});

const { smAndDown } = useDisplay();
</script>
<style lang="scss" scoped>
.card {
  min-height: 300px;
  border-radius: 30px;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .card {
    min-height: 300px;
    border-radius: 18px;
  }
}

@media (max-width: 450px) {
  .card {
    padding: 10px 6px !important;
  }
  .text-md {
    font-size: 12px;
  }
  .avatar-container {
    padding-right: 5px;
  }
}
.star-container {
  border-radius: 10px;
  background-color: #f7f7f7;
}

.card {
  height: 300px;
  overflow: auto;
}
.card::-webkit-scrollbar {
  width: 0px;
}
.star-container {
  margin-top: 0px;
  padding: 2px 25px;
  justify-content: center;
  p {
    margin-right: 6px;
    margin-top: 5px;
  }
  @media (min-width: 600px) {
    padding: 10px;
    max-width: 60px;
    flex-direction: column;
    p {
      margin-right: 0px;
    }
  }
}
.avatar {
  @media (max-width: 500px) {
    width: 35px !important;
    height: 35px !important;
    padding: 0px;
    margin: 0px;
  }
}
.subject {
  white-space: normal;
  overflow: hidden;
  background-color: #8e6ba1 !important;
  text-overflow: ellipsis;
  max-width: 200px;
  min-height: 32px;
  height: fit-content;
}
</style>
